import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.text} className="column is-6">
        <section className="section">
          <div className="has-text-centered">
            <div
              className="card"
              style={{
                width: "80%",
                display: "inline-block"
              }}
            >
              <div className="card-image">
                <div>
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
              </div>
              <div className="card-content">
                <div className="content">
                  <p>{item.text}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string
    })
  )
};

export default FeatureGrid;
