import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";

import linkedin from "../img/social/linkedin.png";
import medium from "../img/social/medium.png";
import angelList from "../img/social/angelList.png";
import vimeo from "../img/social/vimeo.svg";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`
      }}
    >
      <div
        style={{
          display: "flex",
          height: "150px",
          lineHeight: "1",
          justifyContent: "space-around",
          alignItems: "left",
          flexDirection: "column"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            backgroundColor: "rgb(0, 0, 0)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            backgroundColor: "rgb(0, 0, 0)",
            color: "white",
            lineHeight: "1",
            padding: "0.25em"
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                  <div className="column is-4 social social-index">
                    <a
                      title="linkedin"
                      href="https://www.linkedin.com/in/philinvested/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={linkedin}
                        alt="linkedin"
                        style={{ width: "1em", height: "1em" }}
                      />
                    </a>
                    <a
                      title="angelList"
                      href="https://angel.co/philinvested"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={angelList}
                        alt="angelList"
                        style={{ width: "1em", height: "1em" }}
                      />
                    </a>
                    <a
                      title="medium"
                      href="https://medium.com/@philinvested"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={medium}
                        alt="medium"
                        style={{ width: "1em", height: "1em" }}
                      />
                    </a>
                    <a
                      title="vimeo"
                      href="https://vimeo.com/lucreativity"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={vimeo}
                        alt="Vimeo"
                        style={{ width: "1em", height: "1em" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      My Product Management Perspective
                    </h3>
                    <p>
                      I enjoy listening to podcasts such as Software Engineering
                      Daily, The ChangeLog, Robin Hood Snacks, and decided to
                      take a Product Manager Approach and analyze potential new
                      Feature sets that large podcasting platforms, such as
                      Pandora, could implement for customers using data and the
                      tool set of a Product Manager to hypothesize if a feature
                      is value-add to the user
                    </p>
                    <img
                      src="https://media-exp2.licdn.com/dms/image/C4E12AQGo9K961-0SKg/article-cover_image-shrink_423_752/0/1574192912146?e=1661385600&v=beta&t=nW7LWDVsFwyvAp_Cv-4psDpGppm6Yhr8Ugb9IL3vsv8"
                      alt="PodCastingArticleThroughProductManagementPerspective"
                    />
                    <h4>
                      WHERE TO SPOT THE SMART APPLE THAT <br></br> LEADS TO
                      PANDORA’S BOX TO PODCASTING?
                    </h4>
                    <ul>
                      <h4>
                        <em>Reading Options:</em>
                      </h4>
                      <h4>
                        <a
                          href="https://www.linkedin.com/pulse/where-spot-smart-apple-leads-pandoras-box-podcasting-phil-lu/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </a>
                      </h4>
                      <h4>
                        <a
                          href="https://www.slideshare.net/PL89/where-to-spot-the-smart-apple-that-leads-to-pandoras-box-to-podcasting"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SlideShare
                        </a>
                      </h4>
                      <h4>
                        <a
                          href="https://medium.com/@philinvested/where-to-spot-the-smart-apple-that-leads-to-pandoras-box-to-podcasting-c698bbcf50dc"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Medium
                        </a>
                      </h4>
                    </ul>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <a
                      className="btn"
                      href="https://www.linkedin.com/pulse/where-spot-smart-apple-leads-pandoras-box-podcasting-phil-lu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read Here
                    </a>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Books on Building Products
                    </h3>
                    <p>
                      Sharing books I Find Insightful about innovators on
                      creating products
                    </p>
                    <ul>
                      <h3>
                        <li>The Lean Startup</li>
                      </h3>
                      <h3>
                        <li>Zero To One</li>
                      </h3>
                      <h3>
                        <li>The Hard Thing About Hard Things</li>
                      </h3>
                    </ul>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/books">
                      See all books
                    </Link>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest Thoughts
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
